<template>
    <div class="express">
        <full-page :options="options" id="fullpage2">
            <div class="section bg">
                <div class="section-all">
                    <div class="express__kj animate__animated animate__fadeInUp">
                        
                    </div>
                    <div class="express__font animate__animated animate__fadeInUp">
                        
                        <span class="size64">顺风车</span>
                        <span class="size36">撮合车主与用户真实出行需求，共同倡行绿色环保、公益互助的免费合乘平台</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="express-title">
                        <span class="size36">用车体验</span>
                        <span class="size16">Experience</span>
                    </div>
                    <div class="express-list">
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="one" :class="listTop" src="../../../static/images/sfc/ck-one.png" />
                                <div class="one-img one-bg">
                                    <span>01</span>
                                </div>
                                <span class="size36" :class="listBottom">免费合乘</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="two" :class="listTop" src="../../../static/images/sfc/ck-two.png" />
                                <div class="one-img two-bg">
                                    <span>02</span>
                                </div>
                                <span class="size36" :class="listBottom">公益互助</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="three" :class="listTop" src="../../../static/images/sfc/ck-three.png" />
                                <div class="one-img three-bg">
                                    <span>03</span>
                                </div>
                                <span class="size36" :class="listBottom">环保共创</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">我们的产品</span>
                        <span class="size16">Product</span>
                    </div>
                    <div class="express-bocontent">
                        <div class="express-menu express-menu__ac"
                        >
                            <div class="express-menu__li express-menu__active"
                            >顺风车</div>
                        </div>
                        <div class="express-swiper">
                            <div class="express-swiper__content" :class="swiperRight">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <div class="express-swiper__left">
                                        <span class="express-swiper__h">{{item.id}}</span>
                                            <div class="express-swiper__con">
                                                <h2 class="size28">{{item.title}}</h2>
                                                <p class="size16">
                                                    {{item.memo}}
                                                </p>
                                            </div>
                                    </div>
                                    </swiper-slide>
                                </swiper>
                                <div class="express-swiper__naction">
                                    <div class="swiper-container-prev swiper-container__na">
                                        <img src="../../../static/images/home/swiper-left.png" />
                                    </div>
                                    <div class="swiper-container-next swiper-container__na ">
                                        <img src="../../../static/images/home/swiper-right.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="express-swiper__img" :class="swiperLeft">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <img :src="item.img" />
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </full-page>
    </div>
</template>
<script>
import '../../../static/css/fullpage.min.css';
// 引入插件
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
    // components: {
    //     Swiper,
    //     SwiperSlide
    // },
    data(){
        return{
            // 对fullpage的options
            options:{
                afterRender:this.afterRender,
                navigation:true,
                onLeave:this.onLeave
            },
            expreIndex:0,
            // 对swiper的options
            swiperOption:{
                speed:600,
                loop:true,
                nextButton:'.swiper-container-next',
                prevButton:'.swiper-container-prev',
                effect:'fade',
                simulateTouch:false,
            },
            ckList:[
                {
                    title:'两种角色 随心切换',
                    id:'01',
                    memo:'告别路途乏味，乘车更省钱；认证车主，还能轻松赚油费',
                    img:require('../../../static/images/sfc/yw-one.png')
                },
                {
                    title:'实时沟通 灵活行程',
                    id:'02',
                    memo:'车主接单即可接通在线聊天，行程问题实时“面对面”沟通',
                    img:require('../../../static/images/sfc/yw-two.png')
                },
                {
                    title:'预约出行 顺路接送',
                    id:'03',
                    memo:'上班下班提前发布行程，车主乘客选择多，路径规划更轻松',
                    img:require('../../../static/images/sfc/yw-three.png')
                }
            ],
            ckIndex:0,
            swiperShow:true,
            swiperLeft:'',
            swiperRight:'',
            listTop:'',
            listBottom:''
        }
    },
    computed:{
        swiper(){
            return this.$refs.Myswiper.swiper;
        }
    },
    mounted(){
        this.routerTo(4);
    },
    methods:{
        expreTap(index){
            this.expreIndex = index;
            this.ckIndex = 0;
        },
        afterRender(){},
        onLeave(e,n){
            const {index} = n;
            switch(index){
                case 1:
                  this.listTop = 'animate__animated animate__fadeInDown';
                  this.listBottom = 'animate__animated animate__fadeInUp';
                  break;
                case 2:
                  this.swiperLeft = 'animate__animated animate__fadeInLeft';
                  this.swiperRight = 'animate__animated animate__fadeInRight';
                  break;
            }
        },
        changeswiper(){
            this.ckIndex = this.$refs.Myswiper.$swiper.activeIndex;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('../../../static/css/hitch.less');
@import url('../../../static/css/media.less');
</style>